
import client from '@/services/invoice'
import CUTabs from '@/components/CUTabs.vue'
import { Vue, Component } from 'vue-property-decorator'
import { Tab } from '@/models/dto/Tab'
import InvoiceAppBar from '@/components/InvoiceAppBar.vue'
import CheckoutFooter from '@/components/CheckoutFooter.vue'
import CheckoutLoader from '@/components/CheckoutLoader.vue'
import InvoiceInformation from '@/components/InvoiceInformation.vue'
import InvoiceTotal from '@/components/InvoiceTotal.vue'
import InvoiceStatus from '@/components/InvoiceStatus.vue'
import { EventBus } from '@/utils/eventBus'
import { currencyFilter } from '@/utils/string'
import goTo from 'vuetify/lib/services/goto'
import payments from '@/services/payments'
import { CompanyPaymentGatewayDetail } from '@/models/PaymentGateway'
import { InvoiceResult, InvoiceSettings } from '@/models/dto/Invoice'
import InvoicePaymentMethod from '@/components/InvoicePaymentMethod.vue'
import InvoiceReservationTab from '@/components/InvoiceReservationTab.vue'
import CUExpansionPanel from '@/components/CUExpansionPanel.vue'
import { Terms } from '@/models/dto/Terms'
import { defaultPaymentPolicy } from '@/data/paymentPolicy'
import { PaymentMethodKeys } from '@/models/dto'
import { SocialLink } from '@/models/dto/Company'
import { busifyPayEnvironment, staticResource } from '@/utils/env'
import { useFavicon } from '@vueuse/core'
import { v4 } from 'uuid'
import auth from '@/store/modules/auth'

@Component({
  components: {
    CUTabs,
    InvoiceAppBar,
    InvoiceStatus,
    InvoiceInformation,
    CheckoutFooter,
    CheckoutLoader,
    InvoiceTotal,
    InvoiceReservationTab,
    CUExpansionPanel,
  },
})
export default class Invoice extends Vue {
  invoice: InvoiceResult = null
  invoiceSettings: InvoiceSettings = null
  isConverted = false
  isPartiallyConverted = false
  statusHeader: string = null
  statusSubheader: string = null
  linkSubheader = false
  statusLoading = false
  defaultPaymentGateway: CompanyPaymentGatewayDetail = null
  currencyFilter = currencyFilter
  goTo = goTo
  transactions = []
  conversionTransaction = null
  conversionPaymentMethod = null
  errorMessages = []
  displayErrorSnackbar = false
  terms: Terms[] = []
  paymentPolicy = defaultPaymentPolicy
  socialLinks: SocialLink[] = []
  sessionId = null
  tabIndex = null
  isPaymentError = false

  get totalDueNow(): number {
    return this.invoice?.invoiceReservations?.length === 1 &&
      this.invoiceSettings?.amount != null
      ? this.invoiceSettings.amount
      : this.invoice.totalBalances
  }

  get reservationTabs(): Tab[] {
    const addresses = [].concat(
      ...this.invoice.invoiceReservations.map((res) => {
        return res.trip.stops.map((stop) => stop.address.name)
      })
    )
    return this.invoice.invoiceReservations.map((res, i) => {
      return {
        label: res.managedId.toString(),
        component: InvoiceReservationTab,
        props: {
          addresses,
          reservation: res,
          color: this.invoice.company.secondaryColor,
          index: i + 1,
          total: this.invoice.totalBalances,
        },
      }
    })
  }

  get paymentMethodTabs(): Tab[] {
    if (this.statusLoading) {
      return []
    }
    return (
      (this.invoiceSettings?.paymentMethods || [])
        .filter(
          (m) =>
            m.isActive &&
            (m.paymentMethodType.key === PaymentMethodKeys.CREDIT_CARD ||
              (m.paymentMethodType.key === PaymentMethodKeys.ACH &&
                this.isBusifyPay))
        )
        .map((method, index) => {
          return {
            label: method.paymentMethodType.label,
            component: InvoicePaymentMethod,
            props: {
              invoice: this.invoice,
              invoiceSettings: this.invoiceSettings,
              method,
              terms: this.terms,
              totalDueNow: this.totalDueNow,
              allTripsDueNow: this.allTripsDueNow,
              isSquare: this.isSquare,
              isAuthNet: this.isAuthNet,
              isBusifyPay: this.isBusifyPay,
              defaultPaymentGateway: this.defaultPaymentGateway,
              sessionId: this.sessionId,
              isActive: this.tabIndex === index,
            },
          }
        }) || []
    )
  }

  get allTripsDueNow(): boolean {
    return (
      this.invoice.invoiceReservations.findIndex(
        (trip) => trip.dueDate !== null
      ) === -1
    )
  }

  get isSquare(): boolean {
    return this.defaultPaymentGateway?.paymentGatewayTypeKey === 'square'
  }

  get isAuthNet(): boolean {
    return this.defaultPaymentGateway?.paymentGatewayTypeKey === 'auth_net'
  }

  get isBusifyPay(): boolean {
    return this.defaultPaymentGateway?.paymentGatewayTypeKey === 'busify_pay'
  }

  async handleSubmitPayment(): Promise<void> {
    this.goTo(0)
    this.isPartiallyConverted = true
    await this.loadInvoice()
  }

  handleReservationPaymentFailure(): void {
    this.isPaymentError = true
    goTo(0)
  }

  setInvoiceHeaderMessage(): void {
    if (this.isConverted) {
      this.statusHeader = 'Your Payment is Confirmed'
      this.statusSubheader = 'Confirmation was sent to your email.'
      this.linkSubheader = false
    } else if (this.isPartiallyConverted) {
      this.statusHeader = 'Your Payment is Confirmed'
      this.statusSubheader = 'to pay the remaining balance now.'
      this.linkSubheader = true
    }
  }

  handleClickSubheader(): void {
    window.location.reload()
  }

  async loadInvoice(): Promise<void> {
    const resHash = this.$route.params.id
    if (!resHash) {
      return
    }

    const { data: invoice } = await client.byHash(resHash.split(','))
    this.isConverted = invoice.totalBalances === 0
    this.setInvoiceHeaderMessage()

    this.invoiceSettings = invoice.invoiceSettings || new InvoiceSettings()
    this.terms = invoice.terms
    this.invoice = invoice

    if (this.invoice.company) {
      this.socialLinks = invoice.company.socialLinks

      if (!auth.getCompany) {
        auth.setCompany(this.invoice.company)
      }
    }
  }

  async loadCompanyPaymentGateway(): Promise<void> {
    const companyId = this.invoice.company?.companyId
    const { data } = await payments.getDefaultPaymentGateway(companyId)
    if (data.paymentGateways?.length) {
      this.defaultPaymentGateway = data.paymentGateways[0]
    }

    this.sessionId = v4()
  }

  handleSubmitFailure(errorMessage: string): void {
    this.goTo(0)
    const errors = errorMessage.split(';')
    this.errorMessages = errors
    this.displayErrorSnackbar = true
  }

  closeSnackbar(): void {
    this.errorMessages = []
    this.displayErrorSnackbar = false
  }

  loadCompanyBranding(): void {
    const { company } = this.invoice
    const faviconFile = company?.faviconUrl || company?.darkLogoUrl
    window.document.title = `${company?.name || ''} - Invoice`
    if (faviconFile) {
      const icon = useFavicon()
      const faviconUrl = staticResource(faviconFile)
      icon.value = faviconUrl
    }
  }

  async mounted(): Promise<void> {
    EventBus.$on('invoice:convert-success', this.handleSubmitPayment)
    EventBus.$on('invoice:payment-error', this.handleSubmitFailure)
    EventBus.$on(
      'invoice:add-payment-failure',
      this.handleReservationPaymentFailure
    )

    this.statusLoading = true
    await this.loadInvoice()
    await this.loadCompanyPaymentGateway()
    this.statusLoading = false
    this.loadCompanyBranding()
  }

  beforeDestroy(): void {
    EventBus.$off('invoice:convert-success', this.handleSubmitPayment)
    EventBus.$off('invoice:payment-error', this.handleSubmitFailure)
    EventBus.$off(
      'invoice:add-payment-failure',
      this.handleReservationPaymentFailure
    )
  }
}
