var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.loading)?_c('div',{staticClass:"margin-t-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.color,"size":"65"}})],1):(_vm.headerText)?_c('div',{staticClass:"d-flex flex-column justify-center align-center margin-t-6 margin-b-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"rounded-circle d-flex align-center justify-center margin-b-3",class:{
        'background-rust-10': _vm.isPaymentError,
        'background-green-10': !_vm.isPaymentError,
      },style:({
        width: '70px',
        height: '70px',
      })},[(!_vm.isPaymentError)?_c('CUIcon',{staticClass:"margin-t-3 margin-l-2",attrs:{"aria-label":"Success","width":"72px","height":"52px","color":"green"}},[_vm._v(" confirm ")]):_c('CUIcon',{attrs:{"aria-label":"Expired","width":"34px","height":"34px","color":"rust"}},[_vm._v(" alarm_clock ")])],1),_c('h1',[_vm._v(_vm._s(_vm.headerText))]),_c('p',{staticClass:"font-16 margin-t-2 margin-b-0 max-w-four-fifths text-center"},[(_vm.linkSubheader)?_c('span',{staticClass:"text-blue hover:text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.linkSubheader ? _vm.$emit('click:subheader') : () => {}}}},[_vm._v(" Click here ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.subheadingText)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }