
import { InvoiceResult, InvoiceSettings } from '@/models/dto/Invoice'
import { currencyFilter } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class InvoiceTotal extends Vue {
  @Prop({ required: true }) invoice: InvoiceResult
  @Prop({ required: false, default: null }) invoiceSettings: InvoiceSettings

  currencyFilter = currencyFilter

  get invoices(): { id: number; managedId: string; amountDue: number }[] {
    return this.invoice.invoiceReservations.map((res) => {
      return {
        id: res.reservationId,
        managedId: res.managedId,
        amountDue:
          this.invoice.invoiceReservations.length === 1 &&
          this.invoiceSettings?.amount != null
            ? this.invoiceSettings.amount
            : res.balance,
      }
    })
  }

  get totalDueNow(): number {
    return this.invoices.reduce((sum, invoice) => (sum += invoice.amountDue), 0)
  }
}
