
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import currency from 'currency.js'

import reservation from '@/services/reservation'

import { states } from '@/utils/states'
import { InstrumentType, PaymentMethod } from '@/utils/enum'
import { isNotEmptyInput, isCardNumberValidLength } from '@/utils/validators'
import { EventBus } from '@/utils/eventBus'
import { tokenizeAuthNetCard, getCardType } from '@/utils/payment'
import {
  capitalizeFirstLetter,
  formatFullName,
  currencyFilter,
} from '@/utils/string'
import { busifyPayBaseUrl } from '@/utils/env'

import InvoicePayNow from '@/components/InvoicePayNow.vue'
import SquareCardForm from '@/components/SquareCardForm.vue'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import BusifyPayReservationPaymentForm from '@/components/BusifyPayReservationPaymentForm.vue'
import PaymentLoadingScreen from '@/components/PaymentLoadingScreen.vue'

import {
  CompanyPaymentGatewayDetail,
  PaymentGatewayConvertPayload,
  SquareCardFormPaymentInfo,
  TokenizeCreditCardPayload,
} from '@/models/PaymentGateway'
import {
  Address,
  PaymentMethodKeys,
  ReservationManualPaymentRequest,
  ReservationProcessPaymentRequest,
} from '@/models/dto'
import {
  InvoiceResult,
  InvoiceSettings,
  InvoiceSettingsPaymentMethod,
} from '@/models/dto/Invoice'
import { Terms } from '@/models/dto/Terms'
import { WindowMessage } from '@/models/Message'
import { PaymentInstrument } from '@/models/dto/PaymentProfile'

@Component({
  components: {
    InvoicePayNow,
    SquareCardForm,
    AutocompleteAddress,
    BusifyPayReservationPaymentForm,
    PaymentLoadingScreen,
  },
})
export default class InvoicePaymentMethod extends Vue {
  @Prop({ required: true }) readonly invoice: InvoiceResult
  @Prop({ required: true }) readonly invoiceSettings: InvoiceSettings
  @Prop({ required: true }) readonly terms: Terms[]
  @Prop({ required: true }) readonly method: InvoiceSettingsPaymentMethod
  @Prop({ required: true }) readonly totalDueNow: number
  @Prop({ required: true }) readonly allTripsDueNow: boolean
  @Prop({ required: true }) readonly isSquare: boolean
  @Prop({ required: true }) readonly isAuthNet: boolean
  @Prop({ required: true }) readonly isBusifyPay: boolean
  @Prop({ required: false }) readonly sessionId!: string
  @Prop({ required: true }) readonly isActive!: boolean
  @Prop({ required: false })
  readonly defaultPaymentGateway!: CompanyPaymentGatewayDetail

  PaymentMethod = PaymentMethod
  states = states
  currencyFilter = currencyFilter

  isNotEmptyInput = isNotEmptyInput
  isCardNumberValidLength = isCardNumberValidLength

  isSubmitting = false
  info = {
    type: null,
    firstName: null,
    lastName: null,
    accountType: null,
    account: null,
    confirmAccount: null,
    routing: null,
    number: null,
    expiration: null,
    cvc: null,
    address: {
      street: null,
      state: null,
      zip: null,
    },
  }
  paymentErrorMessages = []
  fullAddress = null
  payFullAmount = false

  @Watch('info', { deep: true })
  onPaymentInfoChange(): void {
    this.paymentErrorMessages = []
  }

  get isMethodCard(): boolean {
    return this.method.paymentMethodType.key === PaymentMethodKeys.CREDIT_CARD
  }

  get isMethodACH(): boolean {
    return this.method.paymentMethodType.key === PaymentMethodKeys.ACH
  }

  get isMethodCheck(): boolean {
    return this.method.paymentMethodType.key === PaymentMethodKeys.CHECK
  }

  get isMethodWire(): boolean {
    return this.method.paymentMethodType.key === PaymentMethodKeys.WIRE
  }

  get isMethodOther(): boolean {
    return this.method.paymentMethodType.key === PaymentMethodKeys.OTHER
  }

  get processingFee(): number {
    return this.method?.processingFee || 0
  }

  get shouldDisplayBuyerInfoForm(): boolean {
    return this.isMethodCard && !this.isBusifyPay
  }

  get apiKey(): string {
    return this.defaultPaymentGateway?.apiKey
  }

  get reservationIds(): number[] {
    return this.invoice.invoiceReservations.map((r) => r.reservationId)
  }

  get paymentAmountAsCents(): number {
    const amount = this.payFullAmount
      ? this.invoice.totalBalances
      : this.totalDueNow
    return currency(amount).multiply(100).value
  }

  get paymentAmountAsValue(): number {
    return currency(this.paymentAmountAsCents).divide(100).value
  }

  get invoiceTotalBalances(): number {
    return this.invoice?.totalBalances
  }

  get color(): string {
    return this.invoice.company.primaryColor
  }

  get allowPayMore(): boolean {
    return (
      this.invoice?.invoiceReservations?.length === 1 &&
      !!this.invoiceSettings?.amount &&
      this.invoiceSettings?.amount !== this.invoice.totalBalances
    )
  }

  submitBusifyPayForm(): void {
    const busifyPayCardForm: any = this.$refs['invoice-busify-pay-form']
    if (!busifyPayCardForm) {
      this.isSubmitting = false
      return
    }
    busifyPayCardForm.submit()
  }

  async handlePaymentSubmit(): Promise<void> {
    this.isSubmitting = true

    const isValid = this.$refs['payment-info-form']['validate']()
    if (!isValid) {
      EventBus.$emit(
        'invoice:payment-error',
        'Transaction Failure: Some fields are invalid'
      )
      this.isSubmitting = false
      return
    }

    if (this.isBusifyPay) {
      this.submitBusifyPayForm()
      return
    }

    if (this.isMethodCard && this.isAuthNet) {
      const authNetTokenizePayload = this.buildAuthNetTokenizePayload()
      const tokenizedCardInfo: PaymentGatewayConvertPayload =
        await tokenizeAuthNetCard(
          authNetTokenizePayload,
          this.invoice.invoiceReservations[0]
        )
      await this.submitInvoicePayment(tokenizedCardInfo, authNetTokenizePayload)
    } else if (this.isMethodCard && this.isSquare) {
      // If paying with a card thru Square, use a ref to trigger the payment form
      // to tokenize the card info
      // Remaining submit functionality comes through in handleSquareNonce
      await this.tokenizeSquareCard()
    } else {
      // We're paying through Check, ACH, or Other
      await this.processPaymentManually()
    }

    this.isSubmitting = false
  }

  buildAuthNetTokenizePayload(): TokenizeCreditCardPayload {
    const cardType = getCardType(this.info.number)
    const cardNumber = this.info.number.split(' ').join('')
    const paymentMethodData: TokenizeCreditCardPayload = {
      activeMethod: 'credit_card',
      name: formatFullName(this.info),
      cardNumber,
      mask: this.info.number.slice(-4),
      securityCode: this.info.cvc,
      exp_date: this.info.expiration,
      expirationMonth: this.info.expiration.split('/')[0],
      expirationYear: `${this.info.expiration.split('/')[1]}`,
      type_label: cardType,
      address: {
        street1: this.info.address.street,
        street2: '',
        city: null,
        state: this.info.address.state,
        postal_code: this.info.address.zip,
        name: '',
        lat: null,
        lng: null,
        title: null,
        country: null,
      },
    }

    return paymentMethodData
  }

  async tokenizeSquareCard(): Promise<void> {
    const squarePaymentForm: any = this.$refs['square-payment-form']
    if (!squarePaymentForm) {
      return
    }
    try {
      await squarePaymentForm.requestCardNonce()
    } catch {
      return
    }
  }

  async handleSquareNonce(
    paymentInfo: SquareCardFormPaymentInfo
  ): Promise<void> {
    const { nonce } = paymentInfo
    const tokenizedPaymentInfo: PaymentGatewayConvertPayload = {
      tokens: [nonce],
      paymentGateway: {
        key: 'square',
        id: this.defaultPaymentGateway.companyPaymentGatewayId,
        clientId: this.defaultPaymentGateway.clientKey,
      },
    }

    const paymentMethodData: TokenizeCreditCardPayload = {
      activeMethod: 'credit_card',
      name: '',
      cardholderName: '',
      cardNumber: '',
      securityCode: '',
      mask: paymentInfo.last_4,
      type_label: paymentInfo.card_brand,
      ...paymentInfo,
    }

    await this.submitInvoicePayment(tokenizedPaymentInfo, paymentMethodData)
  }

  async submitInvoicePayment(
    tokenizedCardInfo: PaymentGatewayConvertPayload,
    paymentMethodInfo: TokenizeCreditCardPayload
  ): Promise<void> {
    const reservationIds = this.invoice.invoiceReservations.map(
      (r) => r.reservationId
    )

    let amount = this.totalDueNow
    if (this.payFullAmount) {
      amount = this.invoice.totalBalances
    }
    const processingFeeAmount = currency(amount)
      .multiply(this.processingFee)
      .divide(100)
    const totalDueNowWithProcessingFee =
      currency(amount).add(processingFeeAmount)

    const payload: ReservationProcessPaymentRequest = {
      amount: String(totalDueNowWithProcessingFee),
      reservationIds,
      sendEmail: true,
      nonces: tokenizedCardInfo.tokens,
      payment_gateway: {
        id: this.defaultPaymentGateway.companyPaymentGatewayId,
        key: this.defaultPaymentGateway.paymentGatewayTypeKey,
      },
      payment_method: 'credit_card',
      description: `Card - ${capitalizeFirstLetter(
        paymentMethodInfo.type_label
      )} ${paymentMethodInfo.mask}`,
      billing: paymentMethodInfo,
      processingFeeAmount: String(processingFeeAmount), // Hard-coded until surfaced on back-end
    }

    try {
      const res = await reservation.addReservationPaymentCustomer(
        payload,
        this.invoice.customer.customerId
      )
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('invoice:payment-error', e.response.data.message)
      this.isSubmitting = false
    }
  }

  handleChangeAddressInput(input: string): void {
    this.info.address = { ...this.info.address, street: input }
    this.fullAddress = { ...this.fullAddress, street1: input }
  }

  handleChangeAddress(address: Address): void {
    if (address) {
      this.info = {
        ...this.info,
        address: {
          street: address.street1,
          state: address.state,
          zip: address.postalCode,
        },
      }
      this.fullAddress = address
    } else {
      this.info.address = null
      this.fullAddress = null
    }
  }

  async processPaymentManually(): Promise<void> {
    const reservationIds = this.invoice.invoiceReservations.map(
      (r) => r.reservationId
    )

    let amount = this.totalDueNow
    const payNowForm: any = this.$refs['invoice-pay-now-form']
    if (payNowForm.payFullAmount) {
      amount = this.invoice.totalBalances
    }

    const payload: ReservationManualPaymentRequest = {
      amount: String(amount),
      payment_method: { key: this.method.paymentMethodType.key },
      reservationIds,
      sendEmail: true,
      isManual: true,
      billing: this.info,
    }

    try {
      const res = await reservation.addReservationPaymentCustomer(
        payload,
        this.invoice.customer.customerId
      )
      if (res.status === 200) {
        this.handleSubmitSuccess()
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('invoice:payment-error', e.response.data.message)
      this.isSubmitting = false
    }
  }

  handleMessageEvent(event: MessageEvent): void {
    if (!this.isActive) {
      return
    }
    const { origin, data }: { origin: string; data: WindowMessage } = event
    if (origin !== busifyPayBaseUrl()) {
      return
    }

    if (data.messageName === 'payment-failure') {
      this.handleBusifyPayError(data.messageData)
    } else if (data.messageName === 'payment-success') {
      this.handleBusifyPaySuccess(event.data)
    } else {
      this.handleBusifyPayInvalidForm()
    }
  }

  handleBusifyPayError(errorMessage: Record<string, unknown>): void {
    const message =
      errorMessage?.statusCode === 400
        ? errorMessage?.errorMessage
        : 'There was an error processing your payment. Please confirm all your information is correct and try again.'
    EventBus.$emit('snackbar:error', message)
    this.isSubmitting = false
  }

  handleBusifyPayInvalidForm(): void {
    this.isSubmitting = false
  }

  async handleBusifyPaySuccess(message: WindowMessage): Promise<void> {
    const reservationIds = this.invoice.invoiceReservations.map(
      (r) => r.reservationId
    )
    let amount = this.totalDueNow
    const payNowForm: any = this.$refs['invoice-pay-now-form']
    if (payNowForm.payFullAmount) {
      amount = this.invoice.totalBalances
    }

    const processingFeeAmount = currency(amount)
      .multiply(this.processingFee)
      .divide(100)
    const totalDueNowWithProcessingFee =
      currency(amount).add(processingFeeAmount)

    let description = ''
    const { paymentId } = message.messageData as any
    const { brand, instrument_type, last_four } = message.messageData
      ?.instrument as PaymentInstrument
    if (instrument_type === InstrumentType.CARD) {
      description = `${brand} - ${last_four}`
    } else {
      description = 'Online Bank'
    }

    const payload = {
      amount: String(totalDueNowWithProcessingFee),
      reservationIds,
      billing: this.info,
      paymentId,
      payment_method: this.method.paymentMethodType,
      description,
      processingFeeAmount,
      sendEmail: true,
      isManual: true,
      sessionId: this.sessionId,
    }

    try {
      const res = await reservation.submitInvoiceWithBusifyPay(
        payload,
        this.invoice.customer.customerId
      )
      if (res.status === 200) {
        this.handleSubmitSuccess()
      } else {
        throw new Error('Failed to submit payment')
      }
    } catch (e: any) {
      console.error(e)
      EventBus.$emit('invoice:add-payment-failure')
      this.isSubmitting = false
    }
  }

  async handleSubmitSuccess(): Promise<void> {
    if (this.invoiceSettings.amount) {
      await reservation.clearAmountDue(
        this.invoice.invoiceReservations.map((r) => r.reservationId)
      )
    }
    this.isSubmitting = false
    EventBus.$emit('invoice:convert-success')
  }

  mounted(): void {
    this.info.type = this.method
  }

  created(): void {
    window.addEventListener('message', this.handleMessageEvent)
  }

  beforeDestroy(): void {
    window.removeEventListener('message', this.handleMessageEvent)
  }
}
