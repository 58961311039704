import {
  ApiResult,
  PaymentMethod,
  PaymentMethodType,
  Trip,
  TripChargeType,
} from '.'
import { Terms } from '@/models/dto/Terms'
import { CheckoutPage } from './Checkout'
import { Company } from './Company'
import { Customer } from './Customer'
import { Email } from './Email'
import { CreateNotePayload, Note } from './Note'

export interface InvoiceEntry {
  amount: number
  chargeType: TripChargeType
  createdOn: string
  customerNotes: Note
  description: string
  dueDate: string
  entryType: string
  invoiceChargeDTOs: any
  paymentId: number
  reservationId: number
  transactionId: number
  transactionType: string
}

export interface InvoiceReservation {
  balance: number
  company: Company
  companyCheckoutPage: CheckoutPage
  companyId: number
  dueDate: string
  invoiceCharges: InvoiceEntry[]
  invoiceEntries: InvoiceEntry[]
  managedId: string
  reservationId: number
  totalCharges: number
  totalChargesMinusDiscounts: number
  totalCredits: number
  trip: Trip
}

export interface InvoiceResult {
  invoiceSettings: InvoiceSettings
  company: Company
  checkoutPage: CheckoutPage
  customer: Customer
  totalBalances: number
  totalCredits: number
  totalCharges: number
  totalChargesMinusDiscounts: number
  grandMarketplaceClientTotal: number
  percentage: number
  invoiceUrl: string
  createdDate: string
  invoiceReservations: InvoiceReservation[]
  showTerms: boolean
  einvoiceUrl: string
  isConverted?: boolean
  enablePayLater?: boolean
  invoiceNotes?: Note[]
  paymentMethods: PaymentMethod[]
  colors?: any
  temp?: any
  terms: Terms[]
}

export interface InvoiceSettingsResult extends ApiResult {
  invoiceSettings: InvoiceSettings
}

export interface InvoiceSettings {
  id?: number
  reservationId: number
  contactId: number
  invoiceNumber?: string
  poNumber?: string
  amount?: number
  supportPaymentLink: boolean
  supportPdf: boolean
  includePaymentHistory: boolean
  includeTerms: boolean
  includePaymentMethodPage: boolean
  invoiceNotes?: Note | CreateNotePayload
  paymentMethods?: InvoiceSettingsPaymentMethod[]
}

export interface InvoiceSettingsPaymentMethod {
  id?: number
  invoiceId?: number
  paymentMethodTypeId: number
  processingFee: number
  isActive?: boolean
  paymentMethodType?: PaymentMethodType
}

export interface InvoiceEmail extends Email {
  reservationIds: number[]
  pdfLink: string
  firstName: string
  lastName: string
  timezone?: string
}

// eslint-disable-next-line no-redeclare
export class InvoiceSettings implements InvoiceSettings {
  constructor(invoiceSettings?: Partial<InvoiceSettings>) {
    Object.assign(this, invoiceSettings)
  }
}
