
import { InvoiceResult } from '@/models/dto/Invoice'
import { Note } from '@/models/dto/Note'
import { countryPhoneFormatFilter, formatFullName } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class InvoiceInformation extends Vue {
  @Prop({ required: true }) invoice: InvoiceResult
  @Prop({ required: false, default: () => new Note() }) note: Note

  formatFullName = formatFullName

  get phoneNumber(): string {
    let phoneNumber = countryPhoneFormatFilter(
      this.invoice.customer.phone,
      this.invoice.customer.phoneCountryKey
    )
    if (this.invoice.customer.phoneExtension) {
      phoneNumber = `${phoneNumber} ext. ${this.invoice.customer.phoneExtension}`
    }
    return phoneNumber
  }
}
