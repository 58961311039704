
import { Address } from '@/models/dto'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { InvoiceEntry, InvoiceReservation } from '@/models/dto/Invoice'
import { cleanEmptyHtml, currencyFilter } from '@/utils/string'

@Component({})
export default class InvoiceReservationTab extends mixins(DateMixin) {
  @Prop({ required: true, default: null }) reservation: InvoiceReservation
  @Prop({ required: true, default: null }) addresses: Address[]
  @Prop({ required: true, default: null }) color: string
  @Prop({ required: true, default: null }) index: number
  @Prop({ required: true, default: null }) total: number

  currencyFilter = currencyFilter

  get firstPickup(): string {
    const stop = this.reservation.trip.stops.find((stop) => stop.pickupDatetime)
    if (stop?.address?.title) {
      return stop?.address?.title
    } else if (stop?.address?.city) {
      return `${stop?.address?.city}, ${stop?.address?.state}`
    }
    return stop?.address?.state || '--'
  }

  get firstDropoff(): string {
    const stop = this.reservation.trip.stops.find(
      (stop) => stop.dropoffDatetime
    )
    if (stop?.address?.title) {
      return stop?.address?.title
    } else if (stop?.address?.city) {
      return `${stop?.address?.city}, ${stop?.address?.state}`
    }
    return stop?.address?.state || '--'
  }

  get chargeSubtotal(): number {
    return this.reservation.invoiceCharges.reduce((sum, charge) => {
      sum += charge.amount
      return sum
    }, 0)
  }

  get entrySubtotal(): number {
    return this.reservation.invoiceEntries.reduce((sum, entry) => {
      sum += entry.amount
      return sum
    }, 0)
  }

  getCustomerNote(charge: InvoiceEntry): string {
    return cleanEmptyHtml(charge.customerNotes?.note)
      ? charge.customerNotes.note
      : '--'
  }
}
