var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"background-white",class:{
      'rounded-xl padding-x-6 padding-y-5 margin-t-4 margin-b-9 box-shadow-medium':
        _vm.$vuetify.breakpoint.width >= 650,
    }},[_c('h3',{staticClass:"font-bold font-18 margin-b-3"},[_vm._v("Your Information")]),_c('div',{class:{
        'd-flex justify-start flex-wrap': _vm.$vuetify.breakpoint.width >= 650,
      }},[_c('p',{staticClass:"font-14 margin-b-3 w-one-half overflow-hidden text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.formatFullName(_vm.invoice.customer))+" ")]),_c('p',{staticClass:"font-14 margin-b-3 w-one-half overflow-hidden text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.phoneNumber)+" ")]),_c('p',{staticClass:"font-14 margin-b-1 overflow-hidden w-one-half text-overflow-ellipsis",class:{
          'w-one-half': _vm.invoice.customer.customerAccount,
          'w-full': !_vm.invoice.customer.customerAccount,
        }},[_vm._v(" "+_vm._s(_vm.invoice.customer.email)+" ")]),(_vm.invoice.customer.customerAccount)?_c('p',{staticClass:"font-14 margin-b-1 w-one-half"},[_vm._v(" "+_vm._s(_vm.invoice.customer.customerAccount.name)+" ")]):_vm._e()]),(_vm.note.html)?_c('h3',{staticClass:"font-bold font-18 margin-b-3 margin-t-4"},[_vm._v(" Invoice Notes ")]):_vm._e(),_c('div',{staticClass:"font-14 margin-b-1"},[_c('div',{staticClass:"rich-text-display",domProps:{"innerHTML":_vm._s(_vm.note.html)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }