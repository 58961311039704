
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class InvoiceStatus extends Vue {
  @Prop({ required: true }) header: string
  @Prop({ required: true }) subheader: string
  @Prop({ default: false, type: Boolean }) linkSubheader: boolean
  @Prop({ required: false, default: false }) isPaymentError: boolean
  @Prop({ default: false, type: Boolean }) loading: boolean
  @Prop({ required: false, default: 'primary' }) color: string

  get headerText(): string {
    if (this.isPaymentError) {
      return 'There was an error processing your payment'
    }
    return this.header
  }

  get subheadingText(): string {
    if (this.isPaymentError) {
      return 'Your payment was successful, but we were unable to deduct the balance from your reservation. Please contact us for assistance.'
    }
    return this.subheader
  }
}
