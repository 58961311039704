var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"background-gray-bg d-flex justify-center min-h-full"},[(_vm.invoice)?_c('div',{staticClass:"d-flex flex-column align-center flex-grow-1",class:{ 'margin-x-9': _vm.$vuetify.breakpoint.width >= 650 }},[_c('InvoiceAppBar',{attrs:{"invoice":_vm.invoice,"invoice-number":_vm.invoiceSettings.invoiceNumber}}),_c('v-snackbar',{staticClass:"margin-t-22",attrs:{"color":"#FEEDE6","rounded":"4","top":"","elevation":"0","timeout":"8000"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":_vm.closeSnackbar}},'v-btn',attrs,false),[_c('CUIcon',{attrs:{"width":"24px","height":"24px","color":"bg-gray-6"}},[_vm._v("close")])],1)]}}],null,false,1209563189),model:{value:(_vm.displayErrorSnackbar),callback:function ($$v) {_vm.displayErrorSnackbar=$$v},expression:"displayErrorSnackbar"}},[_c('div',{staticClass:"d-flex align-start"},[_c('CUIcon',{staticClass:"margin-r-2",attrs:{"width":"24px","height":"24px","color":"additional-red"}},[_vm._v(" warning ")]),_c('span',{staticClass:"body-2-regular"},_vm._l((_vm.errorMessages),function(err,errIndex){return _c('p',{key:`error-snackbar-${errIndex}-${err[0] || ''}`},[_vm._v(" "+_vm._s(err)+" ")])}),0)],1)]),_c('InvoiceStatus',{attrs:{"header":_vm.statusHeader,"subheader":_vm.statusSubheader,"link-subheader":_vm.linkSubheader,"is-payment-error":_vm.isPaymentError,"loading":_vm.statusLoading,"color":_vm.invoice.company.primaryColor},on:{"click:subheader":_vm.handleClickSubheader}}),_c('section',{staticClass:"padding-t-10 padding-b-12 d-flex justify-space-between w-full flex-grow-1 flex-shrink-0 flex-wrap",style:({ maxWidth: '1220px' })},[_c('div',{class:{
          'margin-r-5': _vm.$vuetify.breakpoint.width >= 1200,
          'w-628 margin-x-auto':
            _vm.$vuetify.breakpoint.width < 1200 &&
            _vm.$vuetify.breakpoint.width >= 650,
          'w-full': _vm.$vuetify.breakpoint.width < 650,
        }},[(_vm.$vuetify.breakpoint.width >= 650)?_c('div',{class:{
            'd-flex align-center': _vm.$vuetify.breakpoint.width >= 1200,
          }},[_c('h2',{staticClass:"font-bolder margin-r-7",class:{
              'margin-b-2': _vm.$vuetify.breakpoint.width < 1200,
            },attrs:{"id":"section-invoice-summary"}},[_vm._v(" Invoice Summary ")]),_c('div',{staticClass:"d-flex"},[(_vm.invoiceSettings.poNumber)?_c('p',[_c('span',{staticClass:"font-semibold"},[_vm._v("PO #")]),_vm._v(" "+_vm._s(_vm.invoiceSettings.poNumber)+" ")]):_vm._e()])]):_vm._e(),(_vm.$vuetify.breakpoint.width >= 650)?_c('InvoiceInformation',{attrs:{"invoice":_vm.invoice,"note":_vm.invoiceSettings.invoiceNotes}}):_c('div',{staticClass:"background-white padding-x-6 margin-b-6 box-shadow-medium"},[_c('CUExpansionPanel',{attrs:{"default-closed":"","arrow-width":"25px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"font-bolder"},[_vm._v("Invoice Summary")])]},proxy:true}],null,false,1949927256)},[(_vm.invoiceSettings.poNumber)?_c('div',{staticClass:"d-flex margin-t-n1 margin-b-3"},[_c('p',[_c('span',{staticClass:"font-semibold"},[_vm._v("PO #")]),_vm._v(" "+_vm._s(_vm.invoiceSettings.poNumber)+" ")])]):_vm._e(),_c('InvoiceInformation',{attrs:{"invoice":_vm.invoice}}),_c('div',{staticClass:"w-full padding-b-6 padding-t-3"},[_c('h3',{staticClass:"font-bold font-18"},[_vm._v("Invoiced Reservations")]),_c('CUTabs',{style:({ maxWidth: 'calc(100vw - 64px)' }),attrs:{"is-sub-tab":"","tabs":_vm.reservationTabs,"transparent-background":"","align-left":"","color":_vm.invoice.company.secondaryColor}})],1)],1)],1),(_vm.$vuetify.breakpoint.width >= 1200)?_c('div',{staticClass:"w-full background-white rounded-xl padding-x-6 padding-y-5 margin-t-5 box-shadow-medium"},[_c('h3',{staticClass:"font-bold font-18"},[_vm._v("Invoiced Reservations")]),_c('CUTabs',{staticClass:"max-w-588",attrs:{"is-sub-tab":"","tabs":_vm.reservationTabs,"transparent-background":"","align-left":"","color":_vm.invoice.company.secondaryColor}})],1):_vm._e()],1),_c('div',{class:{
          'w-468': _vm.$vuetify.breakpoint.width >= 1200,
          'w-628 margin-x-auto':
            _vm.$vuetify.breakpoint.width < 1200 &&
            _vm.$vuetify.breakpoint.width >= 650,
          'w-full': _vm.$vuetify.breakpoint.width < 650,
        }},[(_vm.$vuetify.breakpoint.width >= 650)?_c('h2',{staticClass:"margin-b-4",attrs:{"id":"section-payment-summary"}},[_vm._v(" Payment Summary ")]):_vm._e(),_c('InvoiceTotal',{attrs:{"invoice":_vm.invoice,"invoice-settings":_vm.invoiceSettings}}),(
            _vm.paymentMethodTabs.length && !_vm.isConverted && !_vm.isPartiallyConverted
          )?_c('div',{staticClass:"background-white padding-x-6 padding-y-5 box-shadow-medium",class:{ 'rounded-xl': _vm.$vuetify.breakpoint.width >= 650 }},[_c('div',{class:{
              'max-w-420 margin-x-auto': _vm.$vuetify.breakpoint.width < 1200,
            }},[_c('CUTabs',{staticClass:"margin-b-7 margin-t-n2",attrs:{"is-sub-tab":"","tabs":_vm.paymentMethodTabs,"transparent-background":"","align-left":"","color":_vm.invoice.company.secondaryColor},on:{"tab:change":function($event){_vm.tabIndex = $event[0]}}})],1)]):_vm._e(),(
            _vm.$vuetify.breakpoint.width < 1200 &&
            _vm.$vuetify.breakpoint.width >= 650
          )?_c('div',{staticClass:"w-full background-white rounded-xl padding-x-6 padding-y-5 margin-t-9 box-shadow-medium"},[_c('h3',{staticClass:"font-bold font-18",attrs:{"id":"section-invoiced-reservations"}},[_vm._v(" Invoiced Reservations ")]),_c('CUTabs',{attrs:{"is-sub-tab":"","tabs":_vm.reservationTabs,"transparent-background":"","align-left":"","color":_vm.invoice.company.secondaryColor}})],1):_vm._e()],1)]),_c('CheckoutFooter',{style:({ maxWidth: '1220px' }),attrs:{"company":_vm.invoice.company}})],1):_vm._e(),(!_vm.invoice)?_c('CheckoutLoader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }