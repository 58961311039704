
import { Vue, Component, Prop } from 'vue-property-decorator'
import { httpHelper, phoneFormatFilter } from '@/utils/string'
import { staticResource } from '@/utils/env'
import { InvoiceResult } from '@/models/dto/Invoice'

@Component({})
export default class InvoiceAppBar extends Vue {
  @Prop({ required: true }) invoice: InvoiceResult
  @Prop({ required: false, default: null }) invoiceNumber: string

  phoneFormatFilter = phoneFormatFilter
  staticResource = staticResource

  get hasWebsiteUrl(): boolean {
    return !!this.invoice?.company?.websiteUrl
  }

  openWebsiteLink(): void {
    if (this.hasWebsiteUrl) {
      window.open(httpHelper(this.invoice.company.websiteUrl), '_blank')
    }
  }
}
