
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
import { PaymentMethod } from '@/utils/enum'
import { states } from '@/utils/states'
import CUCheckbox from '@/components/CUCheckbox.vue'
import CheckoutTermsModal from '@/components/CheckoutTermsModal.vue'
import { InvoiceResult, InvoiceSettings } from '@/models/dto/Invoice'
import { Terms } from '@/models/dto/Terms'
import currency from 'currency.js'

@Component({
  components: {
    CUCheckbox,
    CheckoutTermsModal,
  },
})
export default class InvoicePayNow extends Vue {
  @Prop({ required: true }) readonly invoice: InvoiceResult
  @Prop({ required: true }) readonly invoiceSettings: InvoiceSettings
  @Prop({ required: true }) readonly terms: Terms[]
  @Prop({ required: true }) readonly processingFeePercentage: number
  @Prop({ required: true }) readonly totalDueNow: number
  @Prop({ required: true }) readonly allTripsDueNow: boolean
  @Prop({ type: Boolean, default: false }) readonly isSubmitting: boolean
  @Prop({ type: Boolean, default: false }) readonly payFullAmount: boolean

  currencyFilter = currencyFilter
  PaymentMethod = PaymentMethod
  states = states

  showTermsModal = false
  termsAccepted = false
  totalPaid: string = null
  totalPaidNum: number = null
  termsError = false

  get color(): string {
    return this.invoice.company.primaryColor
  }

  get subTotal(): number {
    return this.invoice?.invoiceReservations?.length === 1 &&
      !this.payFullAmount &&
      this.invoiceSettings?.amount != null
      ? this.invoiceSettings.amount
      : this.invoice.totalBalances
  }

  get processingFeeAmount(): number {
    return currency(this.subTotal)
      .multiply(this.processingFeePercentage)
      .divide(100).value
  }

  get totalPaymentAmount(): number {
    return currency(this.subTotal).add(this.processingFeeAmount).value
  }

  get allowPayMore(): boolean {
    return (
      this.invoice?.invoiceReservations?.length === 1 &&
      !!this.invoiceSettings?.amount &&
      this.invoiceSettings?.amount !== this.invoice.totalBalances
    )
  }

  handleSubmit(): void {
    if (!this.termsAccepted) {
      this.termsError = true
      return
    }
    this.$emit('payment:submit')
  }

  handleCheckboxInput(): void {
    this.termsError = false
    this.termsAccepted = !this.termsAccepted
  }

  handleCloseTerms(): void {
    this.showTermsModal = false
  }

  handleCancelTerms(): void {
    this.termsError = false
    this.showTermsModal = false
    this.termsAccepted = false
  }

  handleAcceptTerms(): void {
    this.termsError = false
    this.showTermsModal = false
    this.termsAccepted = true
  }

  handleShowModal(): void {
    this.showTermsModal = true
  }

  mounted(): void {
    this.totalPaid = currencyFilter(this.totalDueNow)
    this.totalPaidNum = this.totalDueNow
  }
}
